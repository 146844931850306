.Marker {
  width: 4.375rem;
  height: 4.375rem;
  background-color: transparent;
  background-size: contain;
  transition: transform 0.3s;
  transform: scale(1) translate(-50%, -50%);
  position: relative;
}

.Marker:hover {
  cursor: pointer;
  transform: scale(1.1) translate(-45%, -45%);
  z-index: 9;
}

.Marker.train {
  background-image: url('../../../assets/icons/btn-train.svg');
}

.Marker.theatre {
  background-image: url('../../../assets/icons/btn-theatre.svg');
}

.Marker.shopping {
  background-image: url('../../../assets/icons/btn-shopping.svg');
}

.Marker.park {
  background-image: url('../../../assets/icons/btn-park.svg');
}

.Marker.hotel {
  background-image: url('../../../assets/icons/btn-hotel.svg');
}

.Marker.highway {
  background-image: url('../../../assets/icons/btn-highway.svg');
}

.Marker.food {
  background-image: url('../../../assets/icons/btn-food.svg');
}

.Marker.daycare {
  background-image: url('../../../assets/icons/btn-daycare.svg');
}

.Marker.cinema {
  background-image: url('../../../assets/icons/btn-cinema.svg');
}

.Marker.airport {
  background-image: url('../../../assets/icons/btn-airport.svg');
}

@media screen and (min-width: 1024px) {
  .Marker {
    width: 3.9vw;
    height: 3.9vw;
  }
}
