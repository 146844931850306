@font-face {
  font-family: 'URWClassico';
  src: url('../fonts/URWClassico/URWClassico-Reg.woff2') format('woff2'),
    url('../fonts/URWClassico/URWClassico-Reg.woff') format('woff'),
    url('../fonts/URWClassico/URWClassico-Reg.svg#URWClassico-Reg')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik/Aeonik-Regular.woff2') format('woff2'),
    url('../fonts/Aeonik/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik/Aeonik-Bold.woff2') format('woff2'),
    url('../fonts/Aeonik/Aeonik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik/Aeonik-RegularItalic.woff2') format('woff2'),
    url('../fonts/Aeonik/Aeonik-RegularItalic.woff') format('woff');
  font-weight: italic;
  font-style: italic;
  font-display: swap;
}

:root {
  --dark-green: #14281d;
  --primary-color: #1ecc7c;
  --text-color: #dadfdc;
  --grey: #989797;
  --button-bg-color: #eef3f0;

  --main-font-family: 'Aeonik', sans-serif;
  --line-color: #44473f;
  --border: 1px solid var(--line-color);

  --header-font-family: 'URWClassico', sans-serif;

  --font-size-regular: 1rem;
  --font-size-larger: 1.25rem;
  --font-size-large: 1.5rem;

  --default-transition: cubic-bezier(0.4, 0.6, 0.2, 1);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  color: var(--text-color);
  display: flex;
  height: 100%;
  font-family: var(--main-font-family);
  overflow: hidden;
}

a {
  color: var(--dark-green);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--primary-color);
}

h2 {
  font-family: var(--header-font-family);
  font-size: 3.125rem;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 100%;
}

#root {
  width: 100%;
}

@media screen and (min-width: 480px) {
  :root {
    --font-size-regular: 1.25rem;
    --font-size-larger: 1.625rem;
    --font-size-large: 2.8vw;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --font-size-regular: 1.11428571428vw;
    --font-size-larger: 1.44857142856vw;
    --font-size-large: 2.49599999998vw;
    --border: 0.09765625vw solid var(--line-color);
  }

  h2 {
    font-size: 2.78571428569vw;
  }
}
