.InfoWindow {
  background-color: #ffffff;
  position: absolute;
  bottom: -22rem;
  padding-bottom: 1rem;
  width: 100%;
  transition: bottom 0.5s;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0.625rem;
}

.InfoWindow.Show {
  bottom: 6rem;
}

.InfoWindow .Image {
  display: none;
  height: 12.1875rem;
  background-position: center;
  background-size: cover;
  text-indent: -9999px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.Content {
  padding: 1.2rem;
  text-align: center;
  font-size: 1.25rem;
  color: var(--grey);
  line-height: 122.5%;
}

.Content h2 {
  line-height: 3.125rem;
  padding: 0;
  color: var(--dark-green);
  margin: 0.5rem 0 0.65rem 0;
}

.Content a {
  font-size: 1.1875rem;
  line-height: 3.75rem;
  background-color: var(--button-bg-color);
  display: inline-block;
  margin-top: 0.4375rem;
  padding: 0 2rem;
  border-radius: 99rem;
  transition: 0.3s background-color var(--default-transition);
}

.Content a:hover {
  color: initial;
  background-color: var(--text-color);
}

.CloseTooltip {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 999rem;
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 12;
}

.CloseTooltip:hover {
  cursor: pointer;
}

.CloseTooltip span {
  width: 1.875rem;
  height: 1.5px;
  background-color: var(--dark-green);
  position: absolute;
}

.CloseTooltip span:first-child {
  transform: rotate(45deg);
}

.CloseTooltip span:last-child {
  transform: rotate(-45deg);
}

@media screen and (min-width: 480px) {
  .InfoWindow {
    max-width: 27rem;
    height: auto;
    opacity: 1;
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: -27rem;
    background: #ffffff;
    transition: left 0.5s;
    border-radius: 0;
  }

  .InfoWindow.Show {
    left: 0;
    bottom: 0;
  }

  .InfoWindow .Image {
    border-radius: 0;
  }
}

@media screen and (min-width: 1024px) {
  .InfoWindow {
    max-width: 24.07vw;
    left: -24.07vw;
    bottom: 0;
    padding-bottom: 0.89vw;
    border-radius: 0;
    padding: 0.56vw;
  }

  .InfoWindow.Show {
    left: 0;
    bottom: 0;
  }

  .InfoWindow .Image {
    height: 10.86vw;
    border-radius: 0;
  }

  .Content {
    padding: 1.07vw;
    font-size: 1.11vw;
  }

  .Content h2 {
    line-height: 2.79vw;
    margin: 0.45vw 0 0.58vw 0;
  }

  .Content a {
    font-size: 1.06vw;
    line-height: 3.34vw;
    margin-top: 0.39vw;
    padding: 0 1.78vw;
    border-radius: 88.25vw;
  }

  .CloseTooltip {
    width: 3.9vw;
    height: 3.9vw;
    border-radius: 3.9vw;
  }

  .CloseTooltip span {
    width: 1.67vw;
    height: 0.15vw;
  }
}
