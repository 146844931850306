.Navigation {
  font-size: var(--font-size-larger);
  margin-top: 3rem;
}

.Navigation ul {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Navigation ul::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.Navigation li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.Category {
  border-bottom: var(--border);
}

.Category:first-child .CategoryHeader {
  border-top: var(--border);
}

.CategoryHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.CategoryHeader button:first-child {
  flex-grow: 1;
}

.CategoryHeader:hover button {
  cursor: pointer;
  color: var(--primary-color);
}

.CategoryHeader:hover button span {
  background-color: var(--primary-color);
}

.Category.Active {
  padding-bottom: 1.6875rem;
}

.Category button {
  display: flex;
  align-items: center;
  transition: color 0.3s;
  border: none;
  background: none;
  outline: none;
  color: var(--text-color);
  font-weight: 300;
  font-family: var(--main-font-family);
  padding: 1.6rem 0;
}

.Category button:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.Category.Active .CategoryHeader button {
  color: var(--primary-color);
}

.Category > button:hover .MoreButton span {
  background-color: var(--primary-color);
}

.MoreButton {
  width: 2rem;
}

.Active .MoreButton {
  transform: rotate(-45deg);
}

.Active .MoreButton span {
  background-color: var(--primary-color);
}

.Active > button {
  color: var(--primary-color);
}

.MoreButton span {
  transition: background-color 0.3s;
  width: 1.375rem;
  height: 1px;
  display: block;
  background-color: var(--line-color);
}

.MoreButton span:last-child {
  transform: rotate(90deg);
}

.SubCategories {
  font-size: var(--font-size-regular);
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}

.SubCategories button {
  height: 2rem;
  width: 100%;
  padding: 0;
}

.Active .SubCategories {
  height: 100%;
}

.Active .SubCategories li {
  transition: color 0.3s;
  color: var(--text-color);
}

.Active .SubCategories li:hover {
  cursor: pointer;
  color: var(--primary-color);
}

@media screen and (min-width: 480px) {
  .Category button {
    padding: 1.6rem 0;
  }

  .SubCategories button {
    padding: 0;
  }

  .MoreButton span {
    width: 1.875rem;
  }

  .Navigation {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .Category button {
    padding: 1.51893333333vw 0;
  }

  .SubCategories button {
    padding: 0;
  }

  .Category.Active {
    padding-bottom: 1.5vw;
  }

  .MoreButton {
    width: 1.78vw;
  }

  .MoreButton span {
    width: 1.67vw;
    height: 0.09765625vw;
  }

  .SubCategories button {
    height: 1.78vw;
  }
}
