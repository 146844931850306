.Logo {
  width: 5.9375rem;
  height: 1.75rem;
  background: url('./assets/images/logo-park2020.svg');
  background-size: contain;
  display: none;
}

@media screen and (min-width: 480px) {
  .Logo {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .Logo {
    width: 5.29vw;
    height: 1.56vw;
  }
}
