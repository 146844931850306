.App {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.AppNavigation {
  background-color: var(--dark-green);
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: 99;
  max-height: 80vh;
  height: 80vh;
  bottom: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  /* (100% screen size) - (navigation header logo size) - (the navigation header size, assuming it wraps) - (twice the app navigation padding) - (space between margin between navigation header and logo) */
  transform: translate3d(
    0,
    calc(
      (100%) - (1.75rem) - (var(--font-size-large) * 2) - (2rem * 2) -
        (1.5625rem)
    ),
    0
  );
  transition: transform 0.3s;
}

.Open {
  transform: translate3d(0, 0, 0);
}

.Header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header h2 {
  margin-top: 1.5625rem;
  padding-top: 0;
  font-size: var(--font-size-large);
  width: 90%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.ShowNavigation {
  width: 1rem;
  height: 1rem;
  background: url('../../assets/icons/chevron.svg') transparent no-repeat center;
  background-size: contain;
  border: none;
  padding: 0.7rem;
  margin-top: 1.5625rem;
  outline: none;
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.Open .ShowNavigation {
  transform: rotate(0deg);
}

.MapContainer {
  width: 100%;
}

@media screen and (min-width: 350px) {
  .AppNavigation {
    /* (100% screen size) - (navigation header logo size) - (the navigation header size, assuming it does not wrap) - (twice the app navigation padding) - (space between margin between navigation header and logo) */
    transform: translate3d(
      0,
      calc(
        (100%) - (1.75rem) - (var(--font-size-large)) - (2rem * 2) - (1.5625rem)
      ),
      0
    );
  }
  .Open {
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (min-width: 480px) {
  .AppNavigation {
    width: 30%;
    min-width: 30rem;
    border-radius: 0rem;
    position: relative;
    max-height: 100%;
    height: 100vh;
    padding: 3.5rem 5rem;
    transform: translate3d(0, 0, 0);
  }

  .Header h2 {
    margin-top: 3.5rem;
    padding-top: 1.5625rem;
    border-top: var(--border);
  }

  .ShowNavigation {
    display: none;
  }

  .MapContainer {
    width: 70%;
  }
}

@media screen and (min-width: 1024px) {
  .AppNavigation {
    width: 30%;
    min-width: 26.74vw;
    border-radius: 0;
    position: relative;
    max-height: 100%;
    height: 100vh;
    padding: 3.12vw 4.46vw;
    transform: translate3d(0, 0, 0);
  }

  .ShowNavigation {
    display: none;
  }

  .Header h2 {
    margin-top: 3.12vw;
    padding-top: 1.39vw;
    border-top: var(--border);
  }

  .MapContainer {
    width: 70%;
  }
}
