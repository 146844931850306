.Wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 4.375rem;
  z-index: 99;
  position: absolute;
  padding-top: 1rem;
}

.Button {
  background: #ffffff;
  width: 4.375rem;
  height: 4.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 4.375rem;
  border: none;
  outline: none;
  padding: 0;
}

.Button:hover {
  cursor: pointer;
}

.Line {
  width: 1.875rem;
  height: 1.5px;
  background-color: var(--dark-green);
  position: absolute;
}

.Line:first-child {
  transform: rotate(45deg);
}

.Line:last-child {
  transform: rotate(-45deg);
}

@media screen and (min-width: 480px) {
  .Wrapper {
    justify-content: flex-end;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .Wrapper {
    height: 3.9vw;
    padding-top: 0.89vw;
    padding-right: 0.89vw;
  }
  .Button {
    width: 3.9vw;
    height: 3.9vw;
    border-radius: 3.9vw;
  }

  .Line {
    width: 1.67vw;
    height: 0.08357vw;
    background-color: var(--dark-green);
    position: absolute;
  }
}
